<template>
  <div class="join container">
    <div class="baseBox">
      <div class="joinForm" v-if="isVerify == false">
        <div>
          <p>
            휴대전화
            <span>*</span>
          </p>
          <div class="flexB">
            <input type="tel" v-model="mobileNo" @keyup="removeChar" />
            <div class="cert">
              <button class="line" @click="requestCertNumber">인증번호 받기</button>
            </div>
          </div>
        </div>
        <div v-show="isRequestCertNum">
          <p>
            인증번호
            <span>*</span>
          </p>
          <div class="flexB">
            <input class="tel" type="number" v-model="certNumber" @keyup="removeChar" />
            <div class="cert">
              <button class="line" @click="verifyNumber">인증번호 확인</button>
            </div>
          </div>
        </div>
      </div>
      <div class="joinForm" v-if="isVerify">
        <p>
          새 비밀번호
          <span>*</span>
        </p>
        <div class="password">
          <input type="password" v-model="password" />
        </div>
        <p>
          새 비밀번호확인
          <span>*</span>
        </p>
        <div class="password">
          <input type="password" v-model="passwordConfirm" />
        </div>
        <div class="buttonWrap">
          <button class="point full" @click="submit">비밀번호 변경</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { verifyMobile, createCertNumber, updatePwd } from "@/api/index";

export default {
  mixins: [format],
  data() {
    return {
      mobileNo: "",
      isRequestCertNum: false,
      certNumber: "",
      error: false,
      isVerify: false,

      password: "",
      passwordConfirm: "",
    };
  },
  computed: {},
  created() {
    this.$store.dispatch("SET_PAGE_NAME", "비밀번호변경");
    this.$store.dispatch("SET_MENU_VISIBLE", false);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
  },
  methods: {
    removeChar(event) {
      if (event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 37 || event.keyCode == 39) return;
      else event.target.value = event.target.value.replace(/[^0-9]/g, "");
    },
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    requestCertNumber() {
      if (this.mobileNo == "") {
        this.openAlert("휴대폰번호를 입력해 주세요.");
        return;
      } else if (this.mobileNumberCheck(this.mobileNo) == false) {
        this.openAlert("휴대폰번호 형식이 맞지 않습니다.");
        return;
      }
      let data = {
        mobileNo: this.mobileNo,
      };
      this.isRequestCertNum = true;
      createCertNumber(data).then((res) => {
        if (res.data.status == 200) {
          this.openAlert("모바일을 통해서 인증번호를 확인 후 인증번호를 입력해주세요.");
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
    verifyNumber() {
      if (this.certNumber == "") {
        this.openAlert("인증 번호를 입력해 주세요.");
        return;
      }
      let data = {
        mobileNo: this.mobileNo,
        certNumber: this.certNumber,
      };
      verifyMobile(data).then((res) => {
        if (res.data.status == 200) {
          this.isVerify = true;
          this.openAlert("정상적으로 인증이 완료 되었습니다.");
        } else {
          this.isVerify = false;
          this.openAlert(res.data.message);
        }
      });
    },

    submit() {
      if (this.password == "" || this.passwordConfirm == "") {
        this.openAlert("비밀번호를 입력해주세요.");
        return;
      } else if (this.password != this.passwordConfirm) {
        this.openAlert("비밀번호가 다릅니다.");
        return;
      }

      let data = {
        mobileNo: this.mobileNo,
        password: this.password,
      };
      updatePwd(data).then((res) => {
        if (res.data.status == 200) {
          this.$router.replace("/changePasswordDone");
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
  },
};
</script>

export const format = {
  methods: {
    mobileNumberCheck(mobileNo) {
      var regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
      if (regPhone.test(mobileNo) == false) {
        return false;
      }
    },
    numberFormat(inputNumber) {
      if (inputNumber === 0) return "0";
      if (!inputNumber) return "";
      return inputNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    comma(str) {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    },
    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },
    getAge(birth) {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      var monthDay = month + day;
      birth = birth.replace("-", "").replace("-", "");
      var birthdayy = birth.substr(0, 4);
      var birthdaymd = birth.substr(4, 4);
      var age = monthDay < birthdaymd ? year - birthdayy - 1 : year - birthdayy;
      return age;
    },
  },
};
